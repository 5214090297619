<template>
  <span v-for="(breadcrumb, breadcrumbKey) in breadcrumbs" v-bind:key="breadcrumb"
        style="display: inline-block; margin-top: 7px; cursor: pointer;"
        @click.prevent="toggleBC">
    <strong v-if="breadcrumbKey > 0">&nbsp;|&nbsp;</strong>
    <span v-for="(item, itemKey) in breadcrumb.data" v-bind:key="item">
      <span v-if="itemKey > 0">
        <i class="pi pi-chevron-right" style="font-size: 0.7rem"/>
      </span>
      <span :style="(itemKey > 0 || breadcrumb.data.length === 1) && breadcrumb.id !== 1 ? 'color: #82B541;':''">
        {{ item.label }}
      </span>
    </span>
  </span>
  <span v-if="breadcrumbs.length === 0"
        style="color: #82B541; cursor: pointer;"
        @click.prevent="toggleBC"
  >
    {{ _t('ORGANIZATION') }}
  </span>
  <Dialog v-model:visible="breadcrumbsSelectDialog"
          :modal="true"
          :style="{width: '450px'}"
          header=" "
          @close="breadcrumbsSelectDialog = false"
  >
    <TreeTable
        v-model:expandedKeys="expandedOrganizations"
        v-model:selectionKeys="selectedOrgKeys"
        :selectionMode="singleSelection ? 'single' : 'checkbox'"
        :value="organizations"
        autoLayout
        class="p-treetable-sm"
        dynamic="true"
        @node-select="setOrgId($event)"
        @node-unselect="unselectOrgId($event)"
        @node-collapse="clearChildrenNodes($event)"
        @node-expand="appendNode($event)"
    >
      <Column :expander="true" field="name">
        <template #header>
          <div class="p-d-flex p-jc-between">
            <strong style="margin-top: .7rem;">
              {{ _t('label_orgs') }}
            </strong>
            <div class="p-formgroup-inline">
              <Button
                  v-if="!singleSelection"
                  v-tooltip.bottom="_t(withChildOrgs ? 'label_exclude_child_orgs' : 'label_include_child_orgs')"
                  :class="withChildOrgs ?
                      'p-button-rounded p-button-text p-button-lg p-button-secondary' :
                      'p-button-rounded p-button-text p-button-lg p-button-danger'"
                  @click="withChildOrgs = !withChildOrgs"
              >
                <i :class="withChildOrgs ?
                      'mdi mdi-account-multiple-minus' :
                      'mdi mdi-account-multiple-plus'"
                   style="font-size: 16px;"
                />
              </Button>
              <Button v-tooltip.bottom="_t('Clear')"
                      class="p-button-rounded p-button-danger p-button-text p-button-lg"
                      @click="breadcrumbsInitState"
              >
                <i class="pi pi-filter-slash"
                   style="font-size: 16px;"
                />
              </Button>
            </div>
          </div>
        </template>
      </Column>
    </TreeTable>
  </Dialog>
</template>
<script>
import {getTree} from "@/api/tree";
import {getChild} from "../../api/alarmTable";

export default {
  name: "MultiSelectableBreadcrumb",
  emits: ['change', 'selected'],
  props: {
    singleSelection: {
      type: Boolean,
      default: true
    },
    treeParams: {
      type: String,
      default: ''
      // default: 'category_filter=GEOGRAPHICAL_SCOPE&category_filter=BUILDING&category_filter=COMPANY_ORGANISATION&category_filter=ROOM',
    }
  },
  data() {
    return {
      breadcrumbs: [],
      selectedOrgKeys: (JSON.parse(localStorage.getItem('multiOrgSelectedKeys'))) ?? [],
      organizations: [],
      expandedOrganizations: {},
      breadcrumbsSelectDialog: false,
      withChildOrgs: false
    }
  },
  watch: {
    selectedOrgKeys(value) {
      this.selectOrgKeysProcess(value)
    },
    withChildOrgs() {
      localStorage.setItem('withChildOrgs', this.withChildOrgs)
      if (this.singleSelection === true) {
        this.withChildOrgs = false
      }
      this.breadcrumbsInitState()
      this.selectOrgKeysProcess(this.selectedOrgKeys)
    }
  },
  created() {
    localStorage.setItem('withChildOrgs', this.withChildOrgs)
    if (localStorage.getItem('orgData') && this.singleSelection === true) {
      this.breadcrumbs = (JSON.parse(localStorage.getItem('orgData')))
    } else if (localStorage.getItem('multiOrgData') && this.singleSelection === false) {
      this.breadcrumbs = (JSON.parse(localStorage.getItem('multiOrgData'))) ?? []
    }
  },
  mounted() {
    this.getOrganizations()
  },
  methods: {
    breadcrumbsInitState() {
      this.orgFilter = []
      this.selectedOrgKeys = []
      this.breadcrumbs = []
      if (this.singleSelection === true) {
        localStorage.setItem('orgId', this.organizations[0].key)
        localStorage.setItem('orgData', JSON.stringify(this.breadcrumbs))
        this.$emit('change', this.organizations[0].key)
      } else {
        localStorage.setItem('multiOrgIds', JSON.stringify([]))
        localStorage.setItem('multiOrgSelectedKeys', JSON.stringify([]))
        localStorage.setItem('multiOrgData', JSON.stringify([]))
        this.$emit('change', JSON.stringify(this.breadcrumbs))
      }
    },
    toggleBC() {
      this.breadcrumbsSelectDialog = true
    },
    getOrganizations() {
      getTree(null, this.treeParams).then((response) => {
        this.organizations = response.data.map(item => {
          const container = {data: {}};
          container.data.name = item.name;
          container.leaf = !item.hasChildren
          container.key = item.id;
          container.data.parentNames = []
          return container;
        })
        if (this.organizations.length > 0) {
          this.appendNode(this.organizations[0])
          this.expandedOrganizations[this.organizations[0].key] = true;
          if (this.breadcrumbs.length === 0) {
            this.breadcrumbsInitState()
          }
        }
      })
    },
    setOrgId(item) {
      if (item.key === "1" && this.breadcrumbs.length > 0) {
        localStorage.setItem('multiOrgData', JSON.stringify([]))
        localStorage.setItem('multiOrgSelectedKeys', JSON.stringify([]))
        this.breadcrumbs = []
        this.$emit('change', JSON.stringify([]))
      }
      let breadcrumb = item.data.parentNames.length > 0 ?
          [...item.data.parentNames, item.data.name] :
          [item.data.name]

      breadcrumb = breadcrumb.map(element => {
        const container = {};
        container.label = element ?? this._t('ORGANIZATION');

        return container;
      })

      let isPresent = this.breadcrumbs.find(obj => {
        return obj.id === item.key
      })
      if (isPresent === undefined) {
        this.breadcrumbs = [...this.breadcrumbs, {id: item.key, data: breadcrumb}]
      }

      if (item.data.parentNames !== undefined && item.data.parentNames.length > 0) {
        for (let bcKey in this.breadcrumbs) {
          for (let key in item.data.parentNames) {
            if (this.breadcrumbs[bcKey].data.length === 1 && this.breadcrumbs[bcKey].data[0].label === item.data.parentNames[key]) {
              delete this.breadcrumbs[bcKey]
            }
          }
        }
      }
    },
    unselectOrgId(item) {
      delete this.selectedOrgKeys[item]
      this.breadcrumbs = this.breadcrumbs.filter((brc) => brc.id !== item.key)

    },
    appendNode(item) {
      let names = []
      if (item.key !== this.organizations[0].key) {
        names = [...item.data.parentNames, item.data.name]
      }
      getTree(item.key, this.treeParams).then((response) => {
        item.children = response.data.map(element => {
          const container = {data: {}};
          container.data.name = element.name;
          container.leaf = !element.hasChildren
          container.key = element.id;
          container.data.parentNames = names
          return container;
        })
      })
      this.selectedOrgKeys = JSON.parse(localStorage.getItem('multiOrgSelectedKeys'))
    },
    filterBreadcrumbs() {
      for (let key in this.breadcrumbs) {
        if (!Object.keys(this.selectedOrgKeys).includes(this.breadcrumbs[key].id)) {
          this.breadcrumbs.splice(key, 1)
        }
      }
      if (this.breadcrumbs.length === 0 && (this.singleSelection && !localStorage.getItem('orgData'))) {
        this.setOrgId(this.organizations[0])
      }
    },
    selectOrgKeysProcess(value) {
      if (!value) return;
      let multiOrgIds = {}
      for (let index in Object.values(value)) {
        if (Object.values(value)[index].checked) {
          if (this.withChildOrgs === true) {
            getChild(Object.keys(value)[index]).then((response) => {
              response.data.map(
                  (value) => {
                    multiOrgIds[value] = {'checked': true}
                  }
              )
              this.manageOrgKeysProcess(value, multiOrgIds)
            })
          } else {
            multiOrgIds[Object.keys(value)[index]] = Object.values(value)[index]
            this.manageOrgKeysProcess(value, multiOrgIds)
          }
        } else {
          this.manageOrgKeysProcess(value, multiOrgIds)
        }
      }
    },
    manageOrgKeysProcess(value, multiOrgIds) {
      this.filterBreadcrumbs()
      if (this.breadcrumbs.length === 0 && this.singleSelection === true) {
        this.setOrgId(this.organizations[0])
      }
      if (this.singleSelection === true) {
        this.breadcrumbs = this.breadcrumbs.filter(Boolean);
        localStorage.setItem('orgId', this.breadcrumbs[0].id)
        localStorage.setItem('orgData', JSON.stringify(this.breadcrumbs))
        this.$emit('change', this.breadcrumbs[0].id)

      } else {
        this.breadcrumbs = this.breadcrumbs
            .filter(element => {
              if (this.breadcrumbs.length === 1 || (element.id !== this.organizations[0].key && this.breadcrumbs.length > 0)) {
                return true
              }
              return false
            })
            .map(element => {
              let container = {};
              container = element;
              return container;
            })
        localStorage.setItem('multiOrgSelectedKeys', JSON.stringify(multiOrgIds))
        localStorage.setItem('multiOrgIds', JSON.stringify(Object.keys(multiOrgIds).map((item) => {
          return {'id': item}
        })))
        localStorage.setItem('multiOrgData', JSON.stringify(this.breadcrumbs))
        this.$emit('change', JSON.stringify(this.breadcrumbs))
      }
    },
    clearChildrenNodes(item) {
      item.children.map(value => {
        delete this.selectedOrgKeys[value.key]
        item.children = []
      });
      this.selectOrgKeysProcess(this.selectedOrgKeys)
    }
  }
}
</script>
